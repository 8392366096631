<template>
  <div class="missionSchedule">
    <div class="missionSchedule__hours">
      <div class="missionSchedule__hours__item">
        <p class="text-center text-xs font-light mb-1">Start Time</p>
        <vue-timepicker
          style="margin: 0 auto"
          hide-clear-button
          auto-scroll
          v-model="startTimeData"
          @change="emitStartTime"
          :hour-range="disablePastHours ? enabledHours : undefined"
          :minute-range="
            (!enableHoursOnSelect && isSelectedCurrentHour) ||
            (!enableHoursOnSelect && endTimeData === null)
              ? enabledMinutes
              : undefined
          "
        ></vue-timepicker>
      </div>
      <div
        class="missionSchedule__hours__item justify-center items-center min-w-[100px] relative"
      >
        <p v-if="!hasEndTime" class="text-center text-xs font-light mb-1">
          Add End Time
        </p>
        <p v-if="hasEndTime" class="text-center text-xs font-light mb-1">
          End Time
        </p>

        <vue-timepicker
          style="margin: 0 auto"
          v-if="hasEndTime"
          hide-clear-button
          auto-scroll
          v-model="endTimeData"
          @change="emitEndTime"
          :hour-range="disablePastHours ? enabledHours : disabledHours"
          :minute-range="
            (disableMinutes && isSelectedCurrentHour) ||
            (disableMinutes && endTimeData === null)
              ? enabledMinutes
              : disabledMinutes
          "
        ></vue-timepicker>


        <div
          v-if="!hasEndTime"
          @click="addEndTime"
          class="bg-[#36d5f2] rounded-full h-[35px] w-[35px] flex justify-center items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="white"
            class="w-[20px] h-[20px]"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="5"
              d="M12 6v6m0 0v6m0-6h6m-6 0H6"
            />
          </svg>
        </div>
        <div
          v-if="hasEndTime"
          @click="removeEndTime"
          class="bg-[#806157] rounded-full h-[35px] w-[35px] flex justify-center items-center absolute bottom-[-45px]"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 12"
            stroke="white"
            class="w-[20px] h-[20px]"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="5"
              d="M12 6h6m-6 0H6"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import VueTimepicker from "vue2-timepicker";
  import "vue2-timepicker/dist/VueTimepicker.css";
  export default {
    props: {
      startTime: {
        type: String,
        default: null,
      },
      disablePastHours: {
        type: Boolean,
        default: false,
      },
      disableMinutes: {
        type: Boolean,
        default: false,
      },
    },
    components: {
      VueTimepicker,
    },
    data() {
      return {
        noStartTime: false,
        startTimeData: this.startTime,
        endTimeData: null,
        hasEndTime: false,
        emitStartTimeValue: "",
        emitEndTimeValue: "",
        disabledHours: [],
        disabledMinutes: [],
      };
    },
    computed: {
      enableHoursOnSelect() {
        if (!this.startTimeData) {
          return;
        }
        return this.startTimeData.HH > this.getCurrentHoursAndMinutes.HH
          ? true
          : false;
      },
      enabledHours() {
        const currentHour = new Date().getHours();
        return [[currentHour, 23]];
      },
      enabledMinutes() {
        const currentMinute = new Date().getMinutes();
        return [[currentMinute, 59]];
      },
      getCurrentHour() {
        const d = new Date();
        return d.getHours();
      },
      getCurrentMinutes() {
        const d = new Date();
        return d.getMinutes();
      },
      getCurrentTime() {
        const d = new Date();
        const hour = d.getHours() >= 10 ? d.getHours() : `0${d.getHours()}`;
        const minutes =
          d.getMinutes() >= 10 ? d.getMinutes() : `0${d.getMinutes()}`;
        return hour + ":" + minutes;
      },
      getCurrentHoursAndMinutes() {
        const d = new Date();
        return {
          HH:
            d.getHours() < 10 ? String(0 + d.getHours()) : String(d.getHours()),
          mm:
            d.getMinutes() < 10
              ? String(0 + d.getMinutes())
              : String(d.getMinutes()),
        };
      },
      isSelectedCurrentHour() {
        if (this.endTimeData === null) {
          return;
        }
        return this.endTimeData.HH !== undefined &&
          this.endTimeData.HH == this.getCurrentHour
          ? true
          : false;
      },
    },
    methods: {
      currentDate() {
        const d = new Date(),
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate(),
          year = d.getFullYear();
        return [day, month, year].join("-");
      },
      addEndTime() {
        this.hasEndTime = true;
      },
      removeEndTime() {
        this.hasEndTime = false;
      },
      setCurrentTime() {
        const timeObject = {
          HH: String(new Date().getHours()),
          mm: String(new Date().getMinutes()),
        };
        if (this.disablePastHours) {
          this.startTimeData = timeObject;
        }
      },
      emitStartTime(event) {
        if (event.displayTime && this.disablePastHours) {
          const timeObject = {
            HH: String(new Date().getHours()),
            mm: String(new Date().getMinutes()),
          };
          if (event.displayTime >= this.getCurrentTime) {
            this.$emit("startTime", event.displayTime);
            this.noStartTime = false;
          } else {
            this.$emit("startTime", this.getCurrentTime);
            this.startTimeData = timeObject;
            return;
          }
        }
        
        this.emitStartTimeValue = event.displayTime;

        this.disabledHours = [[event.data.HH, 23]];
        this.disabledMinutes = [[event.data.mm, 59]];

        console.log(this.disablePastHours);

        if (
          !this.disablePastHours &&
          this.emitStartTimeValue > this.emitEndTimeValue
        ) {
          this.endTimeData = {
            HH: String(event.data.HH),
            mm: String(event.data.mm),
          };
        }
      },
      emitEndTime(event) {
        if (event.displayTime) {
          this.$emit("endTime", event.displayTime);
          this.noEndTime = false;
        }

        if (this.endTimeData.HH !== undefined) {
          if (
            this.endTimeData.HH == this.getCurrentHour &&
            this.endTimeData.mm < this.getCurrentMinutes
          ) {
            this.endTimeData = {
              HH: this.endTimeData.HH,
              mm: String(this.getCurrentMinutes),
            };
          }
        }

        this.emitEndTimeValue = event.displayTime;

        if (event.displayTime > this.emitStartTimeValue) {
          this.disabledMinutes = [[0, 59]];
        } else {
          this.disabledMinutes = [
            [this.emitStartTimeValue.substring(3, 5), 59],
          ];
          this.endTimeData = {
              HH: String(event.data.HH),
              mm: event.data.mm >=  this.emitStartTimeValue.substring(3, 5) ? String(event.data.mm) : String(this.emitStartTimeValue.substring(3, 5)),
            };
        }
      },
      emitNoStartTime() {
        this.$emit("startTime", null);
        this.startTimeData = null;
        this.noStartTime = true;
      },
      emitNoEndTime() {
        this.$emit("endTime", null);
        this.endTimeData = null;
        this.noEndTime = true;
      },
    },
    created() {
      this.setCurrentTime();
    },
    watch: {
      endTimeData() {
        console.log("endTimeData", this.endTimeData);
        if (this.endTimeData.HH !== undefined) {
          console.log("endTimeData", this.endTimeData.HH);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .missionSchedule {
    margin: 20px auto 0 auto;
    display: flex;
    justify-content: center;
    padding: 1rem;

    &__hours {
      display: flex;
      justify-content: space-between;
      width: 100%;
      &__item {
        display: flex;
        flex-direction: column;
        width: 50%;
        &:first-child {
          margin-right: 10px;
        }
        &:last-child {
          margin-left: 10px;
        }
        &__input {
          color: #806157;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          text-align: center;
          width: 100%;
          background: #efefef;
          padding: 5px 20px;
          border-radius: 3px;
        }
        &__button {
          color: #806157;
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 24px;
          padding: 8px;
          border: 2px solid #806157;
          border-radius: 30px;
        }
      }
    }
    &__days {
      margin-top: 50px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-gap: 12px;
      width: 570px;
      &__day {
        height: 55px;
        width: 55px;
        border: 3px solid #806157;
        border-radius: 50%;
        justify-content: center;
        flex-direction: column;
        &__hour {
          color: #806157;
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 24px;
          text-align: center;
          margin-top: 30px;
          margin-bottom: 30px;
        }
      }
    }
  }
  .missionSchedule__button__active {
    color: #806157;
    font-size: 16px;
    border: none;
    font-weight: bold;
  }
  input[type="time"]::-webkit-calendar-picker-indicator {
    display: none;
  }
  .inputTitle {
    margin-bottom: 10px;
    color: #806157;
    font-weight: 300;
    font-size: 15px;
    text-align: center;
  }
</style>
