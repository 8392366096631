<template>
  <div class="missionSchedule">
    <div class="missionSchedule__hours">
      <div class="missionSchedule__hours__item">
        <p class="text-center text-xs font-light mb-1">Start Time</p>
        <vue-timepicker
          style="margin: 0 auto"
          v-model="startTimeData"
          @change="emitStartTime"
          :hour-range="disablePastHours ? enabledHours : undefined"
          :minute-range="disablePastHours ? enabledMinutes : undefined"
        ></vue-timepicker>
      </div>
      <div class="missionSchedule__hours__item">
        <p class="text-center text-xs font-light mb-1">End Time</p>
        <vue-timepicker
          style="margin: 0 auto"
          v-model="endTimeData"
          @change="emitEndTime"
          :hour-range="disablePastHours ? enabledHours : undefined"
          :minute-range="disablePastHours ? enabledMinutes : undefined"
        ></vue-timepicker>
      </div>
    </div>
  </div>
</template>

<script>
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
export default {
  props: {
    startTime: {
      type: String,
      default: null,
    },
    endTime: {
      type: String,
      default: null,
    },
    disablePastHours: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    VueTimepicker,
  },
  data() {
    return {
      noStartTime: false,
      noEndTime: false,
      startTimeData: this.startTime,
      endTimeData: this.endTime,
    };
  },
  computed: {
    enabledHours() {
      const currentHour = new Date().getHours();
      return [[currentHour, 23]];
    },
    enabledMinutes() {
      const currentMinute = new Date().getMinutes();
      return [[currentMinute, 59]];
    },
  },
  methods: {
    setCurrentTime() {
      const timeObject = {
        HH: String(new Date().getHours()),
        mm: String(new Date().getMinutes()),
      };
      if (this.disablePastHours) {
        this.startTimeData = timeObject;
      }
    },
    emitStartTime(event) {
      if (event.displayTime) {
        this.$emit("startTime", event.displayTime);
        this.noStartTime = false;
      }
    },
    emitEndTime(event) {
      if (event.displayTime) {
        this.$emit("endTime", event.displayTime);
        this.noEndTime = false;
      }
    },
    emitNoStartTime() {
      this.$emit("startTime", null);
      this.startTimeData = null;
      this.noStartTime = true;
    },
    emitNoEndTime() {
      this.$emit("endTime", null);
      this.endTimeData = null;
      this.noEndTime = true;
    },
  },
  created() {
    this.setCurrentTime();
  },
};
</script>

<style lang="scss" scoped>
.missionSchedule {
  margin: 20px auto 0 auto;
  display: flex;
  justify-content: center;
  padding: 1rem 3.5rem;

  &__hours {
    display: flex;
    justify-content: space-between;
    width: 100%;
    &__item {
      display: flex;
      flex-direction: column;
      width: 50%;
      &:first-child {
        margin-right: 10px;
      }
      &:last-child {
        margin-left: 10px;
      }
      &__input {
        color: #806157;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        text-align: center;
        width: 100%;
        background: #efefef;
        padding: 5px 20px;
        border-radius: 3px;
      }
      &__button {
        color: #806157;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        padding: 8px;
        border: 2px solid #806157;
        border-radius: 30px;
      }
    }
  }
  &__days {
    margin-top: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 12px;
    width: 570px;
    &__day {
      height: 55px;
      width: 55px;
      border: 3px solid #806157;
      border-radius: 50%;
      justify-content: center;
      flex-direction: column;
      &__hour {
        color: #806157;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        margin-top: 30px;
        margin-bottom: 30px;
      }
    }
  }
}
.missionSchedule__button__active {
  color: #806157;
  font-size: 16px;
  border: none;
  font-weight: bold;
}
input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}
.inputTitle {
  margin-bottom: 10px;
  color: #806157;
  font-weight: 300;
  font-size: 15px;
  text-align: center;
}
</style>
