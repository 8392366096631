<template>
  <div>
    <label>{{ labelName }}:</label>
    <button
      v-for="(val, idx) in options"
      :key="idx"
      type="button"
      :class="{ activebtn: idx === activeItem }"
      @click="clicked(val, idx)"
    >
      {{ val.name }}
    </button>
  </div>
</template>

<script>
export default {
  props: ["value", "options", "labelName"],
  data() {
    return {
      activeItem: null,
    };
  },
  methods: {
    clicked(val, i) {
      this.$emit("input", val);
      this.activeItem = i;
      // console.log(val);
    },
  },
};
</script>

<style lang="scss">
.activebtn {
  background: red;
}
</style>
