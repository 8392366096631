<template>
  <div class="setupWrapper">
    <AppLoader v-if="isLoading" />
    <div
      style="
        display: grid;
        grid-template-columns: 80px 1fr 80px;
        align-items: start;
      "
    >
    <span class="justify-self-start">
        <img
          class="mx-auto"
          src="../../assets/images/logo-color.png"
          alt="Logo"
          style="
            max-width: 50px;
            object-fit: contain;
          "
      /></span>
      <StepNavigation
        :steps="global_steps"
        :currentstep="3"
        style="margin-bottom: 50px; min-width: 175px"
      >
      </StepNavigation>
      <span
        @click="skipOnboarding"
        style="justify-self: end; color: #c4c4c4; font-weight: 600"
      >
        SKIP
      </span>
    </div>
    <!-- <ProgressBar :wizard-step="Number(3)" /> -->
    <div class="setupWrapper__title">
      <span v-if="setupMissions.length > 0">
        We’re cooking with gas! Let’s continue to the dashboard!
      </span>
      <span v-else> Let's add {{activeChildUsername}}'s first mission! </span>
    </div>

    <div id="missions">
      <div v-if="setupMissions.length == 0">
        <button
          class="flex items-center w-full rounded-full border border-primary p-6 justify-center"
          @click="openSheetAddMission"
        >
          <img
            src="@/pages/guardian/assets/images/add-mission.svg"
            alt="Add Children"
          />
          <span class="text-primary ml-3 font-semibold">Add Mission</span>
        </button>
      </div>
    </div>

    <AppAdd
      v-if="setupMissions.length > 0"
      active-mission-type="Mini mission"
      title="Mini mission"
      :active-title="setupMissions[0].name"
      :active-instructions="setupMissions[0].description"
      :active="true"
      :active-sum="setupMissions[0].sum_money.toString()"
      :active-sum-points="setupMissions[0].sum_points.toString()"
      :type="setupMissions[0].reward_type"
      :timeleft="Number(setupMissions[0].milisecondsDateTime)"
      :stages="setupMissions[0].date_times_mission"
      :avatar="activeChildAvatar"
      class=""
      status="in_progress"
      color="blue"
      mission_type="mini"
      @edit="openMiniEdit(setupMissions[0])"
      @delete="deleteMission(setupMissions[0].id)"
      @details="missionDetails(setupMissions[0])"
      style="box-shadow: none !important; border-radius: 28px"
    />

    <p class="setupWrapper__title px-6 mt-12">{{activeChildUsername}} will receive a notification the first time they log in to the app!</p>

    <!--######################################### START EDIT CALENDAR MODAL ##################################################-->

    <AppModal
      id="missionEditModal"
      :is-open="editMiniModal"
      :has-header="true"
      :has-button="true"
      :has-text="true"
      :has-error="modalHasError"
      :error-message="addError"
      button-text="Save"
      title="Edit mission"
      :button-color="miniCompleteEdit"
      @btnEvent="editMiniMission"
      @close="closeMiniEdit"
    >
      <div class="row" style="width: 100%">
        <div class="col-md-6">
          <div v-if="editMissionShowInputs" style="height: 280px">
            <label for="missionName" class="labelLeft">Mission name</label>
            <AppInput
              id="missionName"
              v-model="editMiniMissionTitle"
              style="
                width: 100%;
                margin: 10px 0 30px 0;
                padding: 0 20px !important;
              "
              :dice="false"
              placeholder="Edit the mission title"
            />
            <label for="missionDescription" class="labelLeft"
              >Description</label
            >
            <textarea
              id="missionDescription"
              v-model="editMiniMissionInstructions"
              class="appInput"
              style="
                width: 100%;
                margin: 10px 0 0 0 !important;
                height: 167px !important;
                border-radius: 10px !important;
                padding: 20px !important;
              "
            />
          </div>

          <div>
            <FunctionalCalendar
              v-if="editCalendarToogleButtonState"
              id="editCalendar"
              :key="$store.state.componentKey"
              v-model="editCalendarData"
              style="height: 280px"
              :configs="editCalendarConfigs"
            >
              <template v-slot:footer>
                <div class="footer">
                  <AppButton
                    class="wishlistToggleButton"
                    color="red"
                    name="Close calendar"
                    :mini="true"
                    style="
                      font-size: 12px;
                      padding: 0 13px !important;
                      align-self: right;
                      margin: 0;
                      font-family: 'Helvetica Neue', Helvetica, Arial,
                        sans-serif;
                      font-weight: 400;
                      position: relative;
                      background: #7a615a;
                      z-index: 99;
                    "
                    @click="editCloseCalendar"
                  >
                    <div class="arrow-up" />
                  </AppButton>
                </div>
              </template>
            </FunctionalCalendar>
          </div>

          <div
            style="
              border-radius: 10px;
              margin-bottom: 10px;
              margin-top: 55px;
              text-align: left;
            "
          >
            <div
              style="
                color: #7a615a;

                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 0px;
              "
            >
              Reward type
            </div>
            <p style="font-size: 12px">
              Select how you would like to reward your child.
            </p>
          </div>
          <div class="row">
            <div class="col-md-4 form__options">
              <p class="form__answer" style="width: 100%; height: 100px">
                <input
                  id="points"
                  :checked="editGetRewardType == 'points'"
                  type="radio"
                  name="reward"
                  value="points"
                  class="missionCheckbox"
                  @change="editChangeMissionRewardType('points')"
                />
                <label class="lbl" for="points" style="font-size: 16px">
                  <img
                    src="../../assets/images/missionStar.png"
                    width="40"
                    height="40"
                    alt=""
                  />
                  Points
                </label>
              </p>
            </div>
            <div class="col-md-4">
              <p class="form__answer" style="width: 100%; height: 100px">
                <input
                  id="money"
                  :checked="editGetRewardType == 'money'"
                  type="radio"
                  name="reward"
                  value="money"
                  class="missionCheckbox"
                  @change="editChangeMissionRewardType('money')"
                />
                <label class="lbl" for="money" style="font-size: 16px">
                  <img
                    src="../../assets/images/missionMoney.png"
                    width="40"
                    height="40"
                    alt=""
                  />
                  Money
                </label>
              </p>
            </div>
            <div class="col-md-4">
              <p class="form__answer" style="width: 100%; height: 100px">
                <input
                  id="wishlist"
                  :checked="editGetRewardType == 'wishlist'"
                  type="radio"
                  name="reward"
                  value="wishlist"
                  class="missionCheckbox"
                  @change="editChangeMissionRewardType('wishlist')"
                />
                <label class="lbl" for="wishlist" style="font-size: 16px">
                  <img
                    src="../../assets/images/missionGift.png"
                    width="40"
                    height="40"
                    alt=""
                  />
                  Wishlist
                </label>
              </p>
            </div>
          </div>

          <AppInput
            v-if="editGetRewardType == 'money'"
            v-model="editMiniMissionMoney"
            class="missions__Money"
            type="number"
            min="1"
            pattern="[0-9\.]+"
            placeholder="How much £ will receive?"
            style="margin-top: 10px !important; margin-bottom: 0 !important"
            @input="inputValidation"
          />
        </div>
        <div class="col-md-6">
          <div class="missionHoursContainer">
            <div class="header_container">
              <div class="wishlist_header">
                <div class="wishlist_header__title">Dates</div>
                <p class="wishlist_header__subtitle">Mission selected dates</p>
              </div>

              <AppButton
                class="wishlistToggleButton"
                name="Open calendar"
                color="red"
                :mini="true"
                style="
                  font-size: 14px;
                  padding: 0 20px !important;
                  align-self: right;
                  margin: 0;
                  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                  font-weight: 400;
                "
                @click="editShowCalendar"
              />
            </div>

            <div class="missionHoursData">
              <div v-if="editMissionDates.length > 0" class="row">
                <div
                  v-for="(date, index) in editMissionDates"
                  :key="index"
                  class="col-md-4"
                >
                  <div class="missionHourItem">
                    <div
                      v-if="date.date > currentDayMethod"
                      class="deleteHourItem"
                      @click="removeHourItem(index)"
                    />
                    <div
                      class="hourItemDate"
                      v-html="parseDate(date.date, 'dd-mm-yy')"
                    />
                  </div>
                </div>
              </div>
              <div v-else style="text-align: center">
                <h4>No dates selected</h4>
              </div>
            </div>
          </div>

          <div class="missionHours">
            <div
              style="
                margin-bottom: 0;
                border-radius: 10px;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                margin-top: 0;
              "
            >
              <div>
                <div
                  style="
                    color: #7a615a;

                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 24px;
                    margin-bottom: 0px;
                  "
                >
                  Hours
                </div>
                <p style="font-size: 12px">Choose hours interval</p>
              </div>
              <div
                style="
                  display: flex;
                  align-items: flex-end;
                  justify-content: center;
                  flex-direction: column;
                "
              >
                <toggle-button
                  :height="30"
                  :width="120"
                  :font-size="12"
                  :labels="{
                    checked: 'All day',
                    unchecked: 'Custom',
                  }"
                  :color="{
                    checked: '#36d5f2',
                    unchecked: '#eacc6b',
                  }"
                  :value="editMissionHoursType"
                  :sync="true"
                  @change="editChangeMissionHoursType()"
                />
              </div>
            </div>

            <div
              v-if="!editMissionHoursType"
              style="width: 100%; margin-top: 30px"
            >
              <AppMissionSchedule
                id="editMissionHours"
                :start-time="edit_mission_start_hour"
                :end-time="edit_mission_end_hour"
                style="margin-bottom: 0; width: 100%"
                @startTime="editGetScheduleStartTimeCalendar"
                @endTime="editGetScheduleEndTimeCalendar"
              />
            </div>
          </div>

          <div class="missionHours">
            <div
              style="
                margin-bottom: 0;
                border-radius: 10px;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                margin-top: 0;
              "
            >
              <div>
                <div
                  style="
                    color: #7a615a;

                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 24px;
                    margin-bottom: 0px;
                  "
                >
                  Reward visibility
                </div>
              </div>

              <toggle-button
                :height="30"
                :width="120"
                :font-size="14"
                :labels="{
                  checked: 'Visible',
                  unchecked: 'Hidden',
                }"
                :color="{
                  checked: '#b8e994',
                  unchecked: '#e55039',
                }"
                :value="editIsRewardVisible"
                :sync="true"
                @change="editChangeRewardVisibility()"
              />
            </div>
          </div>
        </div>

        <div
          v-if="
            editGetRewardType == 'wishlist' && missionWishlistGetter.length > 0
          "
          class="wishlist_container col-md-12"
          style="margin-top: 0; padding: 15px"
        >
          <div class="header_container">
            <div class="wishlist_header">
              <div class="wishlist_header__title">Wishlist items</div>
            </div>
          </div>

          <div
            class="row"
            style="height: 200px; overflow: auto; margin-top: 0"
            :class="{ wishlistActive: wishlistToogleButtonState }"
          >
            <div
              v-for="(item, index) in missionWishlistGetter"
              :key="index"
              class="wishlist_answer col-md-4"
            >
              <input
                :id="item.id"
                :checked="editSelectedWishlistItem == item.id"
                type="radio"
                :value="item.id"
                name="wishlistItem"
                @change="showWishlistItem(item.id)"
              />
              <label class="wishlist_label" :for="item.id">
                <img
                  :src="resolveImage(item.default_picture_link)"
                  width="50"
                  height="50"
                  alt=""
                />
                <div style="margin-left: 20px">
                  <p class="wishlist_title">
                    {{ item.name }}
                  </p>
                  <p class="wishlist_price">
                    Price: &pound;{{ formatPrice(item.price) }}
                  </p>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div
          v-else-if="editGetRewardType == 'wishlist'"
          class="wishlist_container"
        >
          Your child has not added any wishlist items. Please remind
          {{ childUsername }} to add items to their wishlist.
        </div>
      </div>
    </AppModal>

    <!--######################################### END EDIT CALENDAR MODAL ##################################################-->

    <AppModal :is-open="showMiniModal" @close="closeMiniShow">
      <AppForm title="Mission details">
        <div class="show_text">
          {{ showMission.name }}
        </div>
        <div class="show_text">
          {{ showMission.description }}
        </div>
        <div class="show_text">£{{ showMission.sum }}</div>
      </AppForm>
    </AppModal>

    <Sheet :show.sync="sheetAddMission" :on-close="closeSheetAddMission">
      <StepNavigation :steps="steps" :currentstep="currentstep" />

      <div v-if="currentstep == 1" class="addMissionExpl">
        <h1 class="getStartedMissionsTitle">
          Here's a few missions to get you started!
        </h1>
        <div class="predefinedMissions">
          <div class="missionChk">
            <label>
              <input
                v-model="additional"
                type="checkbox"
                value="1"
                @click="check_one(1)"
              /><span>Walking the dog</span>
            </label>
          </div>
          <div class="missionChk">
            <label>
              <input
                v-model="additional"
                type="checkbox"
                value="2"
                @click="check_one(2)"
              /><span>Washing the dishes</span>
            </label>
          </div>
          <div class="missionChk">
            <label>
              <input
                v-model="additional"
                type="checkbox"
                value="3"
                @click="check_one(3)"
              /><span>Taking out the trash</span>
            </label>
          </div>
        </div>

        <AppInput
          v-model="miniMissionTitle"
          maxlength="20"
          :dice="false"
          placeholder=""
          type="text"
          name="Give the mission title"
          label="mission_title"
        />
        <AppInput
          v-model="miniMissionInstructions"
          placeholder=""
          name="Give the mission some instructions"
          label="mission_instructions"
        />
      </div>

      <div v-if="currentstep == 2" class="addMissionExpl">
        <p
          style="
            text-align: center;
            margin-bottom: 25px;
            font-size: 13px;
            margin-top: 30px;
            font-weight: 700;
          "
        >
          Choose a due date for your mini mission.
        </p>
        <FunctionalCalendar
          :key="$store.state.componentKey"
          v-model="calendarData"
          :configs="calendarConfigs"
          @choseDay="showDaySelected"
        />

        <AppButton
          v-if="currentDate"
          :name="'Remove ' + currentDate.backend_format + ' date'"
          color="red"
          :mini="true"
          @click="removeDate"
        />
        <AppButton
          v-if="daily == true && hideSchedule == false"
          :name="confirmScheduleName"
          :color="confirmScheduleColor"
          :mini="true"
          @click="confirmSchedule"
        />
        <AppButton
          v-if="daily == true && hideSchedule == true"
          :name="confirmScheduleName"
          :color="confirmScheduleColor"
          :mini="true"
          @click="editSchedule"
        />
      </div>

      <div v-if="currentstep == 3" class="addMissionExpl">
        <div class="addMissionHoursStep">
          <div>
            <p class="stepFirstSpan" style="margin-top: 30px">
              Does your task have a specific timeframe?
            </p>
            <p class="stepLastSpan" style="margin-bottom: 20px">
              Your mission can be available all day, or only during certain
              hours
            </p>

            <div class="button-wrap" style="gap: 20px;">
              <input
                id="yes-button"
                class="hidden radio-label"
                type="radio"
                name="accept-offers"
                :checked="missionHoursType"
                @change="changeMissionHoursType(true)"
              />
              <label class="button-label" for="yes-button">
                <h1>All Day</h1>
              </label>
              <input
                id="no-button"
                class="hidden radio-label"
                type="radio"
                name="accept-offers"
                :checked="!missionHoursType"
                @change="changeMissionHoursType(false)"
              />
              <label class="button-label" for="no-button">
                <h1>Custom</h1>
              </label>
            </div>
          </div>
          <div class="stepHourButtons" style="display: none">
            <ul class="missionHour">
              <li>
                <input
                  id="allday"
                  type="radio"
                  name="amount"
                  :checked="missionHoursType"
                  @change="changeMissionHoursType(true)"
                />
                <label for="allday">All day</label>
              </li>
              <li>
                <input
                  id="custom"
                  type="radio"
                  name="amount"
                  :checked="!missionHoursType"
                  @change="changeMissionHoursType(false)"
                />
                <label for="custom">Custom</label>
              </li>
            </ul>
          </div>
        </div>

        <div v-if="!missionHoursType" style="width: 100%">
          <!-- <AppMissionSchedule
            :start-time="mission_start_hour"
            :end-time="mission_end_hour"
            style="margin-bottom: 30px; margin-top: 50px"
            @startTime="getScheduleStartTimeCalendar"
            @endTime="getScheduleEndTimeCalendar"
          /> -->

          <AppMissionScheduleOwnTime
            v-if="!missionHoursType"
            :disablePastHours="this.calendarData.selectedDates.length <= 1"
            :endTime="mission_end_hour"
            :startTime="mission_start_hour"
            @endTime="getScheduleEndTimeCalendar"
            @startTime="getScheduleStartTimeCalendar"
          />
        </div>
      </div>

      <div v-if="currentstep == 4" class="addMissionExpl">
        <div
          style="
            text-align: center;
            margin-bottom: 25px;
            font-size: 13px;
            margin-top: 30px;
            font-weight: 700;
          "
        >
          Select how you would like to reward your child.
        </div>

        <div class="form__options">
          <p class="form__answer">
            <input
              id="match_1"
              type="radio"
              name="match"
              value="guy"
              class="missionCheckbox"
              :checked="isChecked"
              @click="changeMissionRewardType('points')"
            />
            <label class="lbl" for="match_1">
              Points
              <p>25 Sonik Points</p>
            </label>
          </p>

          <p class="form__answer" style="margin: 0 10px">
            <input
              id="match_2"
              type="radio"
              name="match"
              value="girl"
              disabled
              class="missionCheckbox"
            />
            <label class="lbl" for="match_2" style="opacity: 0.5">
              Money
              <p>+ 25 Sonik Points</p>
            </label>
          </p>

          <p class="form__answer">
            <input
              id="match_3"
              type="radio"
              name="match"
              value="cat"
              disabled
              class="missionCheckbox"
            />
            <label class="lbl" for="match_3" style="opacity: 0.5">
              Wishlist
              <p>+ 25 Sonik Points</p>
            </label>
          </p>
        </div>

        <radio-set
          v-model="selectedWishlistItem"
          style="display: none"
          label-name="Radio button set"
          :options="missionWishlistGetter"
        />

        <div
          v-if="missionRewardType == 'money'"
          style="font-size: 13px; margin-top: 30px; font-weight: 700"
        >
          Reward Amount
        </div>
        <AppInput
          v-if="missionRewardType == 'money'"
          v-model="miniMissionMoney"
          class="missions__Money"
          type="number"
          min="1"
          placeholder="How much £ will receive?"
          @input="inputValidation"
        />

        <div
          v-if="
            missionRewardType == 'wishlist' && missionWishlistGetter.length > 0
          "
          style="padding: 10px; margin-top: 30px"
        >
          <div class="header_container">
            <div class="wishlist_header">
              <div class="wishlist_header__title">Wishlist items</div>
            </div>
          </div>

          <div
            style="height: 200px; overflow: auto; margin-top: 20px"
            :class="{ wishlistActive: wishlistToogleButtonState }"
          >
            <div
              v-for="(item, index) in missionWishlistGetter"
              :key="index"
              class="wishlist_answer"
              @change="showWishlistItem"
            >
              <input
                :id="item.id"
                v-model="selectedWishlistItem"
                type="radio"
                :value="item"
                :name="item.id"
              />
              <label class="wishlist_label" :for="item.id">
                <img
                  :src="resolveImage(item.default_picture_link)"
                  width="50"
                  height="50"
                  alt=""
                />
                <div style="margin-left: 20px">
                  <p
                    class="wishlist_title"
                    style="
                      display: -webkit-box;
                      height: 20px;
                      -webkit-line-clamp: 1;
                      -webkit-box-orient: vertical;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      font-size: 14px;
                    "
                  >
                    {{ item.name }}
                  </p>
                  <p class="wishlist_price">
                    Price: &pound;{{ formatPrice(item.price) }}
                  </p>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div
          v-else-if="missionRewardType == 'wishlist'"
          class="wishlist_container"
        >
          Your child has not added any wishlist items. Please remind
          {{ childUsername }} to add items to their wishlist.
        </div>

        <div class="addMissionRewardType">
          <div>
            <div
              style="
                margin-bottom: 15px;
                font-size: 13px;
                margin-top: 30px;
                font-weight: 700;
              "
            >
              Reward visibility
            </div>
          </div>

          <toggle-button
            :height="33"
            :width="120"
            :font-size="14"
            :labels="{ checked: 'Visible', unchecked: 'Hidden' }"
            :color="{ checked: '#b8e994', unchecked: '#e55039' }"
            :value="isRewardVisible"
            :sync="true"
            @change="changeRewardVisibility()"
          />
        </div>

        <AppError
          :message="addError"
          style="margin-top: 30px; margin-bottom: -30px"
        />
      </div>

      <StepControls
        v-for="(step, index) in steps"
        :key="index"
        :step="step"
        :calendar="checkPassStep"
        :stepcount="steps.length"
        :currentstep="currentstep"
        :selectedDates="calendarData"
        :stepCustom="true"
        @step-change="stepChanged"
        @step-submit="addMiniMissionSubmit"
      />
    </Sheet>

    <Sheet
      :show.sync="missionDetailsSheet"
      :on-close="closeSheetMissionDetails"
    >
      <div style="font-weight: bold; text-align: center">
        {{ sheetMissionDetails.name }}
      </div>
      <div style="font-weight: 400; text-align: center; font-size: 14px">
        {{ sheetMissionDetails.description }}
      </div>
      <div
        v-if="sheetMissionDetails.reward_type == 'money'"
        style="
          text-align: center;
          font-weight: 800;
          margin: 40px 0;
          font-size: 25px;
          color: var(--main);
        "
      >
        + &pound;{{ formatPrice(sheetMissionDetails.sum_money.toString()) }}
      </div>
      <div
        v-if="sheetMissionDetails.reward_type == 'points'"
        style="
          text-align: center;
          font-weight: 800;
          margin: 40px 0;
          font-size: 25px;
          color: var(--main);
        "
      >
        + {{ sheetMissionDetails.sum_points.toString() }} Points
      </div>
      <div
        v-if="sheetMissionDetails.reward_type == 'wishlist'"
        style="
          text-align: center;
          font-weight: 800;
          margin: 40px 0;
          font-size: 25px;
          color: var(--main);
        "
      >
        Product Reward
      </div>
      <div
        style="
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <button
          style="
            background: var(--main);
            border: none;
            padding: 8px 15px;
            color: white;
            border-radius: 5px;
            margin-right: 10px;
            width: 100%;
          "
          @click="deleteMission(sheetMissionDetails.id)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#fff"
            width="20"
            height="20"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
        </button>
        <button
          style="
            background: var(--main);
            border: none;
            padding: 8px 15px;
            color: white;
            border-radius: 5px;
          "
          @click="openMiniEdit(sheetMissionDetails)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#fff"
            width="20"
            height="20"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
            />
          </svg>
        </button>
      </div>
    </Sheet>

    <Sheet :show.sync="sheetMissionEdit" :on-close="closeSheetMissionEdit">
      <div style="width: 100%">
        <div>
          <div v-if="editMissionShowInputs" style="height: 280px">
            <label for="missionName" style="font-size: 12px; font-weight: 600"
              >Mission name</label
            >
            <AppInput
              id="missionName"
              v-model="editMiniMissionTitle"
              style="width: 100%; margin: 0 0 30px 0"
              :dice="false"
              placeholder="Edit the mission title"
            />
            <label
              for="missionDescription"
              style="font-size: 12px; font-weight: 600"
              >Description</label
            >
            <textarea
              id="missionDescription"
              v-model="editMiniMissionInstructions"
              class="appInput"
              style="
                width: 100%;
                margin: 5px 0 0 0 !important;
                height: 120px !important;
                border-radius: 10px !important;
                padding: 20px !important;
                border: 1px solid #d8d8d8;
                color: #7a615a;
              "
            />
          </div>

          <div>
            <FunctionalCalendar
              v-if="editCalendarToogleButtonState"
              id="editCalendar"
              :key="$store.state.componentKey"
              v-model="editCalendarData"
              style="height: 280px"
              :configs="editCalendarConfigs"
            >
              <template v-slot:footer>
                <div class="footer">
                  <AppButton
                    class="wishlistToggleButton"
                    color="red"
                    name="Close calendar"
                    :mini="true"
                    style="
                      font-size: 12px;
                      padding: 0 13px !important;
                      align-self: right;
                      margin: 0;
                      font-family: 'Helvetica Neue', Helvetica, Arial,
                        sans-serif;
                      font-weight: 400;
                      position: relative;
                      background: #7a615a;
                      z-index: 99;
                    "
                    @click="editCloseCalendar"
                  >
                    <div class="arrow-up" />
                  </AppButton>
                </div>
              </template>
            </FunctionalCalendar>
          </div>

          <div
            style="
              border-radius: 10px;
              margin-bottom: 10px;
              margin-top: 10px;
              text-align: left;
            "
          >
            <div
              style="
                color: #7a615a;
                font-weight: 600;
                font-size: 12px;
                line-height: 24px;
                margin-bottom: 0px;
              "
            >
              Reward type
            </div>
          </div>

          <div class="form__options" style="margin-bottom: 30px">
            <p class="form__answer">
              <input
                id="match_1"
                type="radio"
                name="match"
                value="guy"
                class="missionCheckbox"
                :checked="editGetRewardType == 'points'"
                @click="editChangeMissionRewardType('points')"
              />
              <label class="lbl" for="match_1">
                Points
                <p>25 Sonik Points</p>
              </label>
            </p>

            <p class="form__answer" style="margin: 0 10px">
              <input
                id="match_2"
                type="radio"
                name="match"
                value="girl"
                disabled
                class="missionCheckbox"
              />
              <label class="lbl" for="match_2" style="opacity: 0.5">
                Money
                <p>+ 25 Sonik Points</p>
              </label>
            </p>

            <p class="form__answer">
              <input
                id="match_3"
                type="radio"
                name="match"
                value="cat"
                disabled
                class="missionCheckbox"
              />
              <label class="lbl" for="match_3" style="opacity: 0.5">
                Wishlist
                <p>+ 25 Sonik Points</p>
              </label>
            </p>
          </div>

          <div
            v-if="editGetRewardType == 'money'"
            style="font-size: 13px; margin-top: 30px; font-weight: 700"
          >
            Reward Amount
          </div>
          <AppInput
            v-if="editGetRewardType == 'money'"
            v-model="editMiniMissionMoney"
            class="missions__Money"
            type="number"
            min="1"
            pattern="[0-9\.]+"
            placeholder="How much £ will receive?"
            style="margin-top: 10px !important; margin-bottom: 0 !important"
            @input="inputValidation"
          />
        </div>
        <div class="col-md-6">
          <div class="missionHoursContainer" style="display: none">
            <div class="header_container">
              <div class="wishlist_header">
                <div class="wishlist_header__title">Dates</div>
                <p class="wishlist_header__subtitle">Mission selected dates</p>
              </div>

              <AppButton
                class="wishlistToggleButton"
                name="Open calendar"
                color="red"
                :mini="true"
                style="
                  font-size: 14px;
                  padding: 0 20px !important;
                  align-self: right;
                  margin: 0;
                  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                  font-weight: 400;
                "
                @click="editShowCalendar"
              />
            </div>

            <div class="missionHoursData">
              <div v-if="editMissionDates.length > 0" class="row">
                <div v-for="(date, index) in editMissionDates" :key="index">
                  <div class="missionHourItem">
                    <div
                      v-if="date.date > currentDayMethod"
                      class="deleteHourItem"
                      @click="removeHourItem(index)"
                    />
                    <div
                      class="hourItemDate"
                      v-html="parseDate(date.date, 'dd-mm-yy')"
                    />
                  </div>
                </div>
              </div>
              <div v-else style="text-align: center">
                <h4>No dates selected</h4>
              </div>
            </div>
          </div>

          <div class="missionHours" style="margin-top: 40px">
            <div
              style="
                margin-bottom: 0;
                border-radius: 10px;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                margin-top: 0;
              "
            >
              <div>
                <div
                  style="
                    color: #7a615a;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 24px;
                    margin-bottom: 0px;
                  "
                >
                  Hours
                </div>
              </div>
              <div
                style="
                  display: flex;
                  align-items: flex-end;
                  justify-content: center;
                  flex-direction: column;
                "
              >
                <toggle-button
                  :height="30"
                  :width="120"
                  :font-size="12"
                  :labels="{
                    checked: 'All day',
                    unchecked: 'Custom',
                  }"
                  :color="{
                    checked: '#36d5f2',
                    unchecked: '#eacc6b',
                  }"
                  :value="editMissionHoursType"
                  :sync="true"
                  @change="editChangeMissionHoursType()"
                />
              </div>
            </div>

            <!-- <div
              v-if="!editMissionHoursType"
              style="width: 100%; margin-top: 30px"
            >
              <AppMissionSchedule
                id="editMissionHours"
                :start-time="edit_mission_start_hour"
                :end-time="edit_mission_end_hour"
                style="margin-bottom: 50px; width: 100%"
                @startTime="editGetScheduleStartTimeCalendar"
                @endTime="editGetScheduleEndTimeCalendar"
              />
            </div> -->
          </div>

          <div class="missionHours" style="margin-top: 10px">
            <div
              style="
                margin-bottom: 0;
                border-radius: 10px;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                margin-top: 0;
              "
            >
              <div>
                <div
                  style="
                    color: #7a615a;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 24px;
                    margin-bottom: 0px;
                  "
                >
                  Reward visibility
                </div>
              </div>

              <toggle-button
                :height="30"
                :width="120"
                :font-size="14"
                :labels="{
                  checked: 'Visible',
                  unchecked: 'Hidden',
                }"
                :color="{
                  checked: '#b8e994',
                  unchecked: '#e55039',
                }"
                :value="editIsRewardVisible"
                :sync="true"
                @change="editChangeRewardVisibility()"
              />
            </div>
          </div>
        </div>

        <div
          v-if="
            editGetRewardType == 'wishlist' && missionWishlistGetter.length > 0
          "
          class="wishlist_container col-md-12"
          style="margin-top: 0; padding: 15px"
        >
          <div class="header_container">
            <div class="wishlist_header">
              <div class="wishlist_header__title">Wishlist items</div>
            </div>
          </div>

          <div
            class="row"
            style="height: 200px; overflow: auto; margin-top: 0"
            :class="{ wishlistActive: wishlistToogleButtonState }"
          >
            <div
              v-for="(item, index) in missionWishlistGetter"
              :key="index"
              class="wishlist_answer col-md-4"
            >
              <input
                :id="item.id"
                :checked="editSelectedWishlistItem == item.id"
                type="radio"
                :value="item.id"
                name="wishlistItem"
                @change="showWishlistItem(item.id)"
              />
              <label class="wishlist_label" :for="item.id">
                <img
                  :src="resolveImage(item.default_picture_link)"
                  width="50"
                  height="50"
                  alt=""
                />
                <div style="margin-left: 20px">
                  <p class="wishlist_title">
                    {{ item.name }}
                  </p>
                  <p class="wishlist_price">
                    Price: &pound;{{ formatPrice(item.price) }}
                  </p>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div
          v-else-if="editGetRewardType == 'wishlist'"
          class="wishlist_container"
        >
          Your child has not added any wishlist items. Please remind
          {{ childUsername }} to add items to their wishlist.
        </div>
      </div>
      <button class="updateChildButton" @click="editMiniMission">Update</button>
    </Sheet>

    <div class="nextButtons">
      <button
        :disabled="validateButton"
        class="nextButtons__next"
        :class="{
          nextButtons__active: setupMissions.length > 0,
        }"
        @click="validateNextStep"
      >
        Finish
      </button>
      <!-- <button class="nextButtons__skip" @click="skipOnboarding">
        Skip to dashboard
      </button> -->
    </div>
  </div>
</template>

<script>
  // import SetupCancel from "@/components/SetupCancel";
  // import MissionChild from "@/components/MissionChild";
  import AppButton from "../../components/AppButton";
  import AppAdd from "../../components/AppAdd";
  import AppModal from "../../components/AppModal";
  import AppForm from "../../components/AppForm";
  // import MissionExplication from "@/components/MissionExplication";
  import AppInput from "../../components/AppInput";
  // import AppLessonBigBtn from "@/components/AppLessonBigBtn";
  // import AppWeekdaySelect from "@/components/AppWeekdaySelect";
  import AppMissionSchedule from "../../components/AppMissionSchedule";
  import AppMissionScheduleOwnTime from "../../components/AppMissionScheduleOwnTime";
  import AppError from "../../components/AppError";
  import RadioSet from "../../components/AppCategoryItem";
  // import ChildCardSuccess from "@/components/ChildCardSuccess";
  import StepNavigation from "../../components/wizard/StepNavigation";
  import StepControls from "../../components/wizard/StepControls";
  import Sheet from "../../components/Sheet";
  import { FunctionalCalendar } from "vue-functional-calendar";
  // import Datepicker from "vuejs-datepicker";
  // import ProgressBar from "../../components/ProgressBar";
  import AppLoader from "../../components/AppLoader";
  import axios from "axios";
  import status from "../../utils/statusCode";
  import { mapActions } from "vuex";
  import { mapGetters } from "vuex";
  import config from "../../config";

  export default {
    components: {
      AppError,
      Sheet,
      // ProgressBar,
      AppLoader,
      // SetupCancel,
      // MissionChild,
      AppButton,
      AppAdd,
      AppModal,
      AppForm,
      // MissionExplication,
      AppInput,
      // AppLessonBigBtn,
      // AppWeekdaySelect,
      AppMissionSchedule,
      AppMissionScheduleOwnTime,
      RadioSet,
      // ChildCardSuccess,
      StepNavigation,
      StepControls,
      FunctionalCalendar,
      // Datepicker,
    },
    props: {
      next: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        activeChildAvatar: null,
        activeChildUsername: null,
        setupMissions: [],
        sheetMissionDetails: [],
        missionDetailsSheet: false,
        sheetMissionEdit: false,
        isLoading: true,
        sheetAddMission: false,
        childUsername: null,
        additional: [],
        currentstep: 1,
        indicatorclass: true,
        step: 1,
        active: 1,
        firststep: true,
        nextStep: "",
        lastStep: "",
        laststep: false,
        passStep: false,
        global_steps: [
          {
            id: 1,
            title: "STEP 1",
            icon_class: "fa fa-map-marker",
          },
          {
            id: 2,
            title: "STEP 2",
            icon_class: "fa fa-folder-open",
          },
          {
            id: 3,
            title: "STEP 3",
            icon_class: "fa fa-paper-plane",
          },
        ],
        steps: [
          {
            id: 1,
            title: "STEP 1",
            icon_class: "fa fa-map-marker",
          },
          {
            id: 2,
            title: "STEP 2",
            icon_class: "fa fa-folder-open",
          },
          {
            id: 3,
            title: "STEP 3",
            icon_class: "fa fa-paper-plane",
          },
          {
            id: 4,
            title: "STEP 4",
            icon_class: "fa fa-paper-plane",
          },
        ],
        /////////////////////////////
        modal1: false,
        modalHasError: false,
        miniMissionTitle: "",
        never: false,
        daily: false,
        custom: false,
        miniMissionInstructions: "",
        miniMissionMoney: "",
        activeChild: 0,
        error: "",
        miniMission: {},
        currentDay: null,
        missionTitle: "",
        missionInstructions: "",
        miniMoney: null,
        miniActive: false,
        editMiniModal: false,
        time_conditions: [],
        currentCondition: {
          type: "hour",
          start_date: null,
          end_date: null,
        },
        daysIndexs: [],
        currentTimes: {},
        calendar: {
          disabled_dates: {
            to: new Date(new Date().setDate(new Date().getDate() - 1)),
          },
          default: {
            date: null,
          },
          highlighted: {
            dates: [],
          },
        },
        currentDate: null,
        childMissions: null,
        hideSchedule: false,
        confirmScheduleName: "Apply schedule",
        confirmScheduleColor: "blue",
        finalTimesConditions: [],
        addError: "",
        daysIndexsCalendar: [],
        time_conditions_calendar: [],
        editMiniMissionTitle: "",
        editMiniMissionInstructions: "",
        editMiniMissionMoney: "",
        showMiniModal: false,
        showMission: {},

        calendarConfigs: {
          dateFormat: "dd-mm-yyyy",
          isMultipleDatePicker: true,
          hiddenElements: ["leftAndRightDays"],
          disabledDates: ["beforeToday"],
          dayNames: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
        calendarData: {},
        missionHoursType: true,
        mission_start_hour: "00:00",
        mission_end_hour: "23:59",
        selectedWishlistItem: null,
        missionRewardType: "",
        isRewardVisible: true,
        wishlistToogleButtonState: false,
        componentKey: 0,
        wishlistToogleButtonText: "Done",
        isChecked: false,

        editDateCalendar: {},
        editMissionHoursType: true,
        editGetRewardType: "",
        editSelectedWishlistItem: null,
        editMissionProductId: null,
        editWishlistToogleButtonState: false,
        editIsRewardVisible: false,
        editMissionDates: {},
        edit_mission_start_hour: "00:00",
        edit_mission_end_hour: "23:59",
        editMissionId: null,
        edit_mission_start_hour_switch: "00:00",
        edit_mission_end_hour_switch: "23:59",
        editMissionShowInputs: true,
        editCalendarToogleButtonState: false,
        editCalendarToogleButtonText: "Select more dates",
        editCalendarData: {},
        editCalendarConfigs: {
          dateFormat: "dd-mm-yyyy",
          isMultipleDatePicker: true,
          hiddenElements: ["leftAndRightDays"],
          disabledDates: ["beforeToday"],
        },
      };
    },
    computed: {
      ...mapGetters([
        "getSetupChildrens",
        "getSetupMissions",
        "getSetupChildrenMissions",
        "miniMissionsNewGetter",
        "miniMissionsNewArrayGetter",
        "missionWishlistGetter",
      ]),
      validateButton() {
        if (this.setupMissions.length > 0) return false;
        else return true;
      },
      checkPassStep() {
        return this.passStep;
      },
      currentDayMethod() {
        var d = new Date(),
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate(),
          year = d.getFullYear();
        return [day, month, year].join("-");
      },
      miniComplete() {
        if (this.missionRewardType == "money") {
          return this.miniMissionTitle.length > 2 &&
            this.miniMissionInstructions.length > 2 &&
            this.miniMissionMoney != 0 &&
            Object.keys(this.calendarData).length > 0 &&
            Object.keys(this.calendarData.selectedDates).length > 0
            ? "blue"
            : "";
        } else if (this.missionRewardType == "points") {
          return this.miniMissionTitle.length > 2 &&
            this.miniMissionInstructions.length > 2 &&
            Object.keys(this.calendarData).length > 0 &&
            Object.keys(this.calendarData.selectedDates).length > 0
            ? "blue"
            : "";
        } else if (this.missionRewardType == "wishlist") {
          return this.miniMissionTitle.length > 2 &&
            this.miniMissionInstructions.length > 2 &&
            Object.keys(this.calendarData).length > 0 &&
            Object.keys(this.calendarData.selectedDates).length > 0 &&
            this.selectedWishlistItem != null
            ? "blue"
            : "";
        } else {
          return "";
        }
      },
      miniCompleteEdit() {
        if (this.editGetRewardType == "money") {
          return this.editMiniMissionTitle.length > 2 &&
            this.editMiniMissionInstructions.length > 2 &&
            this.editMiniMissionMoney != 0
            ? "blue"
            : "";
        } else if (this.editGetRewardType == "wishlist") {
          return this.editMiniMissionTitle.length > 2 &&
            this.editMiniMissionInstructions.length > 2 &&
            this.missionWishlistGetter.length > 0
            ? "blue"
            : "";
        } else if (
          this.editMiniMissionTitle.length > 2 &&
          this.editMiniMissionInstructions.length > 2
        ) {
          return "blue";
        } else {
          return "";
        }
      },
      wishlistButtonCheck() {
        if (this.selectedWishlistItem != null) return "red";
        else return "";
      },
    },
    async created() {
      // this.fetchChildren();
      // this.setupMissions = this.miniMissionsNewArrayGetter;

      this.fetchChildren();
    },
    methods: {
      ...mapActions([
        "fetchSetupChildrens",
        "fetchSetupMissions",
        "saveSetupMission",
        "deleteSetupMission",
        "editSetupMission",
        "fetchSetupMissionsChild",
        "fetchMiniMissionsNew",
        "fetchMissionWishlistAction",
        "addMiniMission",
        "editMission",
        "deleteMiniMission",
        "finishWizardPage",
        "TOAST",
      ]),
      async fetchChildren() {
        const token = localStorage.getItem("guardian_token");
        const userId = localStorage.getItem("guardian_user_id");
        const family = localStorage.getItem("guardian_family_id");

        const response = await axios.post(config.API_HOST + "/children", {
          userId: userId,
          token: token,
          family_id_check: family,
        });

        const { statusCode } = response.data;

        if (status.success(statusCode)) {
          if (response.data.data.length > 0) {
            localStorage.setItem(
              "guardian_setup_active_child_id",
              response.data.data[0].users_id
            );

            this.activeChild = response.data.data[0].users_id;
            this.activeChildAvatar = response.data.data[0].user.avatarLinkEncode;
            this.activeChildUsername = response.data.data[0].user.last_name;

            this.fetchMissions(response.data.data[0].users_id);
          }

          setTimeout(() => {
            this.isLoading = false;
          }, 1000);
        } else if (status.invalidToken(statusCode)) {
          this.deleteStorage();
        } else if (status.error(statusCode)) {
          this.TOAST("Something went wrong! Please contact our support team.");
        }
      },
      async fetchMissions(id) {
        const token = localStorage.getItem("guardian_token");
        const userId = localStorage.getItem("guardian_user_id");
        const family = localStorage.getItem("guardian_family_id");

        const response = await axios.get(
          "/missions/index" +
            "?userId=" +
            userId +
            "&token=" +
            token +
            "&family_id_check=" +
            family
        );
        const { statusCode } = response.data;

        if (status.success(statusCode)) {
          // this.setupMissions = response.data.data;

          response.data.data.forEach((e) => {
            // console.log(e);
            if (e.users_id == id) {
              // console.log(e);
              e.category.forEach((k) => {
                // console.log(k);
                if (k.name == "mini") {
                  // console.log("ok");
                  if (k.subcategory !== null && k.subcategory !== "") {
                    // console.log(k.subcategory.missions);
                    this.setupMissions = k.subcategory.missions;
                  }
                }
              });
            }
          });

        //   console.log(response.data.data);
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          TOAST("Something went wrong! Please contact our support team.");
        }
      },
      missionDetails(mission) {
        // console.log(mission);
        this.sheetMissionDetails = mission;
        this.openSheetMissionDetails();
      },
      openSheetMissionDetails() {
        this.missionDetailsSheet = true;
      },
      closeSheetMissionDetails() {
        this.missionDetailsSheet = false;
      },
      openSheetMissionEdit() {
        this.sheetMissionEdit = true;
      },
      closeSheetMissionEdit() {
        this.sheetMissionEdit = false;
      },
      //   fetchChildren() {
      //     const token = localStorage.getItem("guardian_token");
      //     const userId = localStorage.getItem("guardian_user_id");
      //     const family = localStorage.getItem("guardian_family_id");
      //     // const activeChild = localStorage.getItem("activeChild");

      //     axios
      //       .post("/children", {
      //         userId: userId,
      //         token: token,
      //         family_id_check: family,
      //       })
      //       .then((response) => {
      //         // console.log("children fetched");
      //         const { statusCode } = response.data;
      //         if (status.success(statusCode)) {
      //           this.$store.commit("FETCH_SETUP_CHILDRENS", response.data.data);
      //           if (response.data.data.length > 0) {
      //             localStorage.setItem(
      //               "guardian_setup_active_child_id",
      //               response.data.data[0].users_id
      //             );
      //             this.childActivate(
      //               response.data.data[0].users_id,
      //               this.getSetupChildrens[0].username,
      //               this.getSetupChildrens[0].balance
      //             );
      //           }

      //           setTimeout(() => {
      //             this.isLoading = false;
      //           }, 1000);
      //         } else if (status.invalidToken(statusCode)) {
      //           this.deleteStorage();
      //         } else if (status.error(statusCode)) {
      //           this.TOAST(
      //             "Something went wrong! Please contact our support team."
      //           );
      //         }
      //       })
      //       .catch((error) => {
      //         throw error;
      //       });
      //   },
      closeSheetAddMission() {
        this.sheetAddMission = false;
      },
      openSheetAddMission() {
        this.sheetAddMission = true;
      },
      check_one: function (val) {
        this.additional = [];
        // console.log(val);
        if (val === 1) {
          this.miniMissionTitle = "Walk the dog";
          this.miniMissionInstructions = "Walk the dog once a day";
        } else if (val === 2) {
          this.miniMissionTitle = "Wash the dishes";
          this.miniMissionInstructions = "Wash the dishes after dinner";
        } else if (val === 3) {
          this.miniMissionTitle = "Take out the trash";
          this.miniMissionInstructions = "Take out the trash every morning";
        } else {
          this.miniMissionTitle = "";
          this.miniMissionInstructions = "";
        }
      },
      stepChanged: function (step) {
        if (step == 2 && Object.keys(this.calendarData).length > 0) {
          if (this.calendarData.selectedDates.length > 0) {
            this.passStep = false;
            this.currentstep = step;
          } else {
            this.passStep = true;
          }
        } else {
          this.passStep = true;
        }

        this.currentstep = step;

        if (step != 2) {
          this.currentstep = step;
          this.passStep = false;
        }
      },
      resolveImage(src) {
        return config.HOST + src;
      },
      openModal1() {
        this.miniMissionTitle = "";
        this.miniMissionInstructions = "";
        this.miniMissionMoney = "";
        this.dateCalendar = {};
        this.calendarData = {};
        this.missionHoursType = true;
        this.getRewardType = "";
        this.missionRewardType = "";
        this.selectedWishlistItem = null;
        this.isRewardVisible = true;
        this.wishlistToogleButtonState = false;
        if (this.activeChild == 0) {
          this.error = "You must to select a child to add mission!";
          this.modal1 = false;
        } else {
          this.modal1 = true;
        }
        this.isChecked = false;
        // console.log(this.isChecked);
      },
      changeRewardVisibility() {
        this.isRewardVisible = !this.isRewardVisible;
        // console.log(this.isRewardVisible);
      },

      /*************************** Start Mission Add ******************************/
      showDaySelected() {
        if (
          this.currentstep == 2 &&
          this.calendarData.selectedDates.length > 0
        ) {
          this.passStep = false;
        } else {
          this.passStep = true;
        }
      },
      changeMissionRewardType(type) {
        this.missionRewardType = type;
        if (type == "money") {
          this.$store.state.missionSelectedMoney = true;
        } else {
          this.$store.state.missionSelectedMoney = false;
        }
      },
      // onlyNumeric() {
      //   // this.inputNumber = this.inputNumber.replace(/[^0-9]/g, "");
      //   if ((key < "0" || key > "9") && key !== ".") {
      //     event.preventDefault();
      //   }
      // },

      inputValidation(evt) {
        // alert(evt);
        evt = evt ? evt : window.event;

        if ((evt < "0" || evt > "9") && evt !== "." && evt !== "-") {
          evt.preventDefault();
        } else {
          return true;
        }
      },
      fetchMissionWishlist() {
        this.fetchMissionWishlistAction(this.activeChild);
      },
      wishlistButtonToggle() {
        this.wishlistToogleButtonState = !this.wishlistToogleButtonState;
        this.wishlistToogleButtonText = this.wishlistToogleButtonState
          ? "Show"
          : "Done";
        // console.log(this.wishlistToogleButtonState);
      },
      editShowCalendar() {
        this.editCalendarToogleButtonState = true;
        this.editMissionShowInputs = false;
      },
      editCloseCalendar() {
        this.editCalendarToogleButtonState = false;
        this.editMissionShowInputs = true;
      },
      returnDate() {
        const datesArray = [];
        const startHour = this.mission_start_hour;
        const endHour = this.mission_end_hour;

        // console.log(this.calendarData);

        this.calendarData.selectedDates.forEach(function (value) {
          const datesFormat = {
            start_datetime: value.date + " " + startHour,
            end_datetime: value.date + " " + endHour,
          };
          datesArray.push(datesFormat);
        });

        this.dateCalendar = datesArray;

        // console.log(this.dateCalendar);
      },
      forceRerender() {
        this.$store.state.componentKey += 1;
      },
      changeMissionHoursType(value) {
        // this.missionHoursType = !this.missionHoursType;
        this.missionHoursType = value;
        if (!this.missionHoursType) {
          this.mission_start_hour = "00:00";
          this.mission_end_hour = "23:59";
        } else {
          this.mission_start_hour = "00:00";
          this.mission_end_hour = "23:59";
        }
        // console.log(this.missionHoursType);
      },
      showWishlistItem(item) {
        this.editSelectedWishlistItem = item;
        // console.log(this.editSelectedWishlistItem);
      },
      closeModal1() {
        this.modal1 = false;
        this.miniMissionTitle = "";
        this.miniMissionInstructions = "";
        this.miniMissionMoney = "";
        this.dateCalendar = {};
        this.calendarData = {};
        this.missionHoursType = true;
        this.getRewardType = "";
        this.missionRewardType = "";
        this.selectedWishlistItem = null;
        this.wishlistToogleButtonState = false;
        this.isRewardVisible = true;
        this.isChecked = false;
        this.modalHasError = false;
        this.addError = "";
        this.currentstep = 1;
        this.additional = [];
        this.forceRerender();
      },
      addMiniMissionSubmit() {
        // console.log("Step 1");
        if (
          this.miniMissionTitle.length <= 2 ||
          this.miniMissionInstructions.length <= 2
        ) {
          this.modalHasError = true;
          this.addError = "Please complete all fields!";
        } else if (Object.keys(this.calendarData).length === 0) {
          this.modalHasError = true;
          this.addError = "Please select one or more dates from calendar!";
        } else if (this.missionRewardType == "") {
          this.modalHasError = true;
          this.addError = "Please select a reward type!";
        } else if (
          this.missionRewardType == "wishlist" &&
          this.selectedWishlistItem == null
        ) {
          this.modalHasError = true;
          this.addError = "You need to select a wishlist item!";
        } else if (
          (this.missionRewardType == "money" && this.miniMissionMoney == 0) ||
          (this.missionRewardType == "money" && this.miniMissionMoney == "")
        ) {
          this.modalHasError = true;
          this.addError = "Reward sum cannot be 0 or empty.";
        } else {
          this.modalHasError = false;
          this.addError = "";
          this.returnDate();
          const {
            miniMissionTitle,
            miniMissionInstructions,
            miniMissionMoney,
          } = this;

          if (Object.keys(this.dateCalendar).length > 0) {
            if (this.missionRewardType == "money") {
              if (
                miniMissionTitle &&
                miniMissionInstructions &&
                miniMissionMoney
              ) {
                const mission = {
                  categoryId: 1,
                  name: miniMissionTitle,
                  description: miniMissionInstructions,
                  sum_money: miniMissionMoney,
                  sum_points: 25,
                  reward_type: "money",
                  child_id: this.activeChild,
                  time_condition: this.dateCalendar,
                  is_reward_visible: this.isRewardVisible,
                };

                this.addMiniMission(mission);
                this.closeSheetAddMission();
              }
            } else if (this.missionRewardType == "wishlist") {
              if (miniMissionTitle && miniMissionInstructions) {
                const mission = {
                  categoryId: 1,
                  name: miniMissionTitle,
                  description: miniMissionInstructions,
                  sum_money: 0,
                  sum_points: 25,
                  reward_type: "wishlist",
                  product_id: this.selectedWishlistItem.id,
                  child_id: this.activeChild,
                  time_condition: this.dateCalendar,
                  is_reward_visible: this.isRewardVisible,
                };

                this.addMiniMission(mission);
                this.closeSheetAddMission();
              }
            } else if (this.missionRewardType == "points") {
              // console.log("Step Points");
              if (miniMissionTitle && miniMissionInstructions) {
                // console.log("Step Validation");
                // console.log("Active child: " + this.activeChild);
                const mission = {
                  categoryId: 1,
                  name: miniMissionTitle,
                  description: miniMissionInstructions,
                  sum_money: 0,
                  sum_points: 25,
                  reward_type: "points",
                  child_id: this.activeChild,
                  time_condition: this.dateCalendar,
                  is_reward_visible: this.isRewardVisible,
                };

                this.addMiniMission(mission);
                this.fetchChildren();
                this.closeSheetAddMission();
              }
            }
          } else {
            this.modalHasError = true;
            this.addError = "Please select one or more dates from calendar!";
          }
        }
      },
      /*************************** End Mission Add ******************************/

      /*************************** Start Mission Edit ******************************/
      removeHourItem(index) {
        this.editMissionDates.splice(index, 1);
      },
      editChangeMissionRewardType(type) {
        this.editGetRewardType = type;
      },
      openMiniEdit(mission) {
        // this.editMiniModal = true;
        // this.modalHasError = false;
        this.addError = "";
        this.editMiniMissionId = mission.id;
        this.editMiniMissionTitle = mission.name;
        this.editMiniMissionInstructions = mission.description;
        this.editMiniMissionMoney = mission.sum_money;
        this.editGetRewardType = mission.reward_type;
        this.editSelectedWishlistItem = mission.product_id;
        this.edit_mission_start_hour_switch =
          mission.date_times_mission[0].start_time;
        this.edit_mission_end_hour_switch =
          mission.date_times_mission[0].end_time;
        // console.log(mission);
        this.editIsRewardVisible = mission.is_reward_visible;

        // console.log(this.edit_mission_start_hour_switch);
        // console.log(this.edit_mission_end_hour_switch);

        if (
          mission.date_times_mission[0].start_time == "00:00:00" &&
          mission.date_times_mission[0].end_time == "23:59:00"
        ) {
          this.editMissionHoursType = true;
          // console.log("i am here");
        } else {
          this.editMissionHoursType = false;
          this.edit_mission_start_hour =
            mission.date_times_mission[0].start_time;
          this.edit_mission_end_hour = mission.date_times_mission[0].end_time;
          // console.log("nu merge");
        }

        // console.log(this.editSelectedWishlistItem);

        this.editMissionDates = {};

        const editCalendarDates = [];

        // console.log(mission.date_times_mission);

        mission.date_times_mission.forEach(function (date) {
          if (date.start_date) {
            const dateStructure = {
              date: date.start_date,
              start_hour: date.start_time,
              end_hour: date.end_time,
            };
            editCalendarDates.push(dateStructure);
          }
        });

        this.editMissionDates = editCalendarDates;
        this.closeSheetMissionDetails();
        this.openSheetMissionEdit();
        // console.log(this.editMissionDates);
      },
      closeMiniEdit() {
        this.editMiniModal = false;
        this.editCalendarData = {};
        this.forceRerender();
        this.editMissionShowInputs = true;
        this.editCalendarToogleButtonState = false;
        this.modalHasError = false;
        this.addError = "";
      },
      editCalendarButtonToggle() {
        this.editCalendarToogleButtonState =
          !this.editCalendarToogleButtonState;
        this.editMissionShowInputs = !this.editMissionShowInputs;

        if (this.editCalendarToogleButtonState) {
          this.editCalendarToogleButtonText = "Select more dates";
        } else {
          this.editCalendarToogleButtonText = "Hide calendar";
        }

        /*this.editCalendarToogleButtonText = this.editCalendarToogleButtonState
        ? "Select more dates"
        : "Hide calendar";
    },*/
      },
      parseDate(dateStr, format) {
        const regex = format
          .toLocaleLowerCase()
          .replace(/\bd+\b/, "(?<day>\\d+)")
          .replace(/\bm+\b/, "(?<month>\\d+)")
          .replace(/\by+\b/, "(?<year>\\d+)");

        const parts = new RegExp(regex).exec(dateStr) || {};
        const { year, month, day } = parts.groups || {};
        const finalDate =
          parts.length === 4 ? new Date(year, month - 1, day) : undefined;

        const monthNames = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];

        const finday = finalDate.getDate();
        const finmonthIndex = finalDate.getMonth();
        const finyear = finalDate.getFullYear();

        return `<h1 class="selectedDay">${finday}</h1> <h4 class="selectedMonth">${monthNames[finmonthIndex]}</h4> <h5 class="selectedYear">${finyear}</h5>`;
      },
      editReturnDate() {
        const datesArray = [];
        const startHour = this.mission_start_hour;
        const endHour = this.mission_end_hour;

        // console.log(this.editCalendarData);

        this.editCalendarData.selectedDates.forEach(function (value) {
          const datesFormat = {
            start_datetime: value.date + " " + startHour,
            end_datetime: value.date + " " + endHour,
          };
          datesArray.push(datesFormat);
        });

        this.editDateCalendar = datesArray;

        // console.log(this.editDateCalendar);
      },
      editChangeRewardVisibility() {
        this.editIsRewardVisible = !this.editIsRewardVisible;
        // console.log(this.editIsRewardVisible);
      },
      editChangeMissionHoursType() {
        const editStartTime = this.edit_mission_start_hour_switch;
        const editEndTime = this.edit_mission_end_hour_switch;

        this.editMissionHoursType = !this.editMissionHoursType;
        if (this.editMissionHoursType) {
          this.edit_mission_start_hour = "00:00";
          this.edit_mission_end_hour = "23:59";
        } else {
          this.edit_mission_start_hour = editStartTime;
          this.edit_mission_end_hour = editEndTime;
        }
        // console.log(this.edit_mission_start_hour_switch);
        // console.log(this.edit_mission_end_hour_switch);
      },
      editMiniMission() {
        // console.log(this.editSelectedWishlistItem);

        if (
          this.editMiniMissionTitle.length <= 2 ||
          this.editMiniMissionInstructions.length <= 2
        ) {
          this.addError = "Complete all fields.";
        } else {
          const finalTimestamps = [];
          const startHour = this.edit_mission_start_hour;
          const endHour = this.edit_mission_end_hour;

          this.editMissionDates.forEach(function (value) {
            const datesArr = {
              start_datetime: value.date + " " + startHour,
              end_datetime: value.date + " " + endHour,
            };
            finalTimestamps.push(datesArr);
          });

          if (Object.keys(this.editCalendarData).length > 0) {
            if (this.editCalendarData.selectedDates.length > 0) {
              this.editReturnDate();
              this.editCalendarData.selectedDates.forEach(function (value) {
                const datesArr2 = {
                  start_datetime: value.date + " " + startHour,
                  end_datetime: value.date + " " + endHour,
                };
                finalTimestamps.push(datesArr2);
              });
            }
          }

          // console.log(this.editMissionDates);
          // console.log(this.editDateCalendar);

          // console.log("--------------- final timestamps ---------------------");
          // console.log(finalTimestamps);
          // console.log("--------------- final timestamps ---------------------");

          // console.log(this);

          const {
            editMiniMissionTitle,
            editMiniMissionInstructions,
            editMiniMissionMoney,
          } = this;

          if (this.editGetRewardType == "money") {
            if (
              editMiniMissionTitle &&
              editMiniMissionInstructions &&
              editMiniMissionMoney
            ) {
              const mission = {
                categoryId: 1,
                mission_id: this.editMiniMissionId,
                name: editMiniMissionTitle,
                description: editMiniMissionInstructions,
                sum_money: editMiniMissionMoney,
                sum_points: 25,
                reward_type: "money",
                time_condition: finalTimestamps,
                reward_hidden: this.editIsRewardVisible,
              };
              // console.log(mission);
              this.editMission(mission);
            }
          } else if (
            this.editGetRewardType == "wishlist" &&
            this.missionWishlistGetter.length > 0
          ) {
            if (editMiniMissionTitle && editMiniMissionInstructions) {
              const mission = {
                categoryId: 1,
                mission_id: this.editMiniMissionId,
                name: editMiniMissionTitle,
                description: editMiniMissionInstructions,
                sum_money: 0,
                sum_points: 25,
                reward_type: "wishlist",
                product_id: this.editSelectedWishlistItem,
                time_condition: finalTimestamps,
                reward_hidden: this.editIsRewardVisible,
              };

              // console.log(mission);
              this.editMission(mission);
            }
          } else if (this.editGetRewardType == "points") {
            if (editMiniMissionTitle && editMiniMissionInstructions) {
              const mission = {
                categoryId: 1,
                mission_id: this.editMiniMissionId,
                name: editMiniMissionTitle,
                description: editMiniMissionInstructions,
                sum_money: 0,
                sum_points: 25,
                reward_type: "points",
                time_condition: finalTimestamps,
                reward_hidden: this.editIsRewardVisible,
              };
              // console.log(mission);
              this.editMission(mission);
            }

            this.editMiniMissionId = null;
            this.editMiniMissionTitle = "";
            this.editMiniMissionInstructions = "";
            this.editMiniMissionMoney = "";
            this.editCalendarData = {};
            this.modalHasError = false;
            this.addError = "";
            this.closeSheetMissionEdit();
          } else {
            this.addError =
              "You selected Wishlist as reward type, but there are no wishlist items selected. Please choose another reward type!";
            this.modalHasError = true;
          }
          this.closeSheetMissionEdit();
        }
      },
      /*************************** End Mission Edit ******************************/

      openMiniShow(mission) {
        this.showMiniModal = true;
        this.showMission.name = mission.name;
        this.showMission.description = mission.description;
        this.showMission.sum = mission.sum;
      },
      closeMiniShow() {
        this.showMiniModal = false;
        this.showMission.name = "";
        this.showMission.description = "";
        this.showMission.sum = "";
      },

      /* Changing start time input method */
      getScheduleStartTimeCalendar(event) {
        // this.currentDate.start_date = event;
        // console.log(event);
        this.mission_start_hour = event;
        this.pushScheduleCalendar(this.mission_start_hour);
      },

      /* Changing end time input method */
      getScheduleEndTimeCalendar(event) {
        // this.currentDate.end_date = event;
        this.mission_end_hour = event;
        // this.pushScheduleCalendar(this.currentDate);
      },

      editGetScheduleStartTimeCalendar(event) {
        // this.currentDate.start_date = event;
        // console.log(event);
        this.edit_mission_start_hour = event;
        this.pushScheduleCalendar(this.edit_mission_start_hour);
      },
      editGetScheduleEndTimeCalendar(event) {
        // this.currentDate.end_date = event;
        this.edit_mission_end_hour = event;
        // this.pushScheduleCalendar(this.currentDate);
      },

      childActivate(id, username, balance) {
        // this.activeChild = id;
        this.error = "";
        this.$store.state.setup.activeChildId = id;
        // this.fetchSetupMissionsChild();
        console.log(balance);
        this.activeChild = id;
        this.childUsername = username;
        this.fetchMiniMissionsNew(id);
        // var searchChild = this.getSetupMissions.findIndex(item => item.users_id === id);
        // if(searchChild >= 0)
        // 	this.childMissions = this.getSetupMissions[searchChild].missions[0].missions;
      },
      confirmSchedule() {
        if (this.time_conditions.length > 0) {
          this.finalTimesConditions = this.time_conditions;
          this.hideSchedule = true;
          this.confirmScheduleName = "Edit schedule!";
          this.confirmScheduleColor = "red";
        } else {
          this.addError = "Complete weekend schedule if you select daily!";
        }
      },
      editSchedule() {
        this.hideSchedule = false;
        this.confirmScheduleName = "Confirm schedule!";
        this.confirmScheduleColor = "blue";
      },
      deleteMission(id) {
        var x = confirm("Are you sure you want to delete this mission?");
        if (x) {
          this.closeSheetMissionDetails();
          this.deleteMiniMission(id);
        } else return false;

        // this.$swal({
        //   title: "Are you sure?",
        //   text: "Do you really want to delete this mission?",
        //   icon: "warning",
        //   buttons: true,
        //   dangerMode: true,
        // }).then((willDelete) => {
        //   if (willDelete) {
        //     this.deleteMiniMission(id);
        //     this.$store.state.missionSelectedMoney = false;
        //   }
        // });
      },
      async validateNextStep() {
        return await Promise.all([this.finishWizardPage()]).then(() => {
          if (this.setupMissions.length > 0) {
            this.$router.replace("/guardian");
          }
        });
      },
      skipOnboarding() {
        swal({
          title: "Are you sure you want to skip to the dashboard?",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            this.$router.replace("/guardian");
          }
        });
      },
    },
    watch: {
      editGetRewardType: function (val) {
        if (val == "wishlist") {
          this.fetchMissionWishlist();
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  // Datepicker scss begin
  .vdp-datepicker {
    width: 100%;
  }

  .vdp-datepicker__calendar {
    border: 0 !important;
    width: 100% !important;
  }

  .day__month_btn {
    color: #7a615a;

    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 24px;
    text-align: center;
  }

  .vfc-week .vfc-day span.vfc-span-day,
  .vfc-week .vfc-day {
    color: #7a615a !important;
  }

  .vfc-week .vfc-day span.vfc-span-day.vfc-today {
    color: white !important;
  }

  .next {
    color: #7a615a !important;
  }

  .day-header {
    display: none !important;
  }

  .cell {
    border: 3px solid #7a615a !important;
    border-radius: 100% !important;
    height: 60px !important;
    width: 60px !important;
    margin: 10px;
  }

  .day {
    color: #7a615a;

    font-style: normal;
    font-weight: normal;
    font-size: 20px !important;
    line-height: 24px;
    text-align: center;
  }

  .day:hover {
    background: #ffffff !important;
    border: 3px solid #36d5f2 !important;
    box-shadow: 0px 4px 20px rgba(54, 213, 242, 0.8) !important;

    color: #36d5f2 !important;
  }

  .highlighted {
    background: #36d5f2 !important;
    border: 3px solid #36d5f2 !important;
    box-shadow: 0px 4px 20px rgba(54, 213, 242, 0.7);

    color: #ffffff !important;
  }

  .selected {
    background: #ffffff !important;
    border: 3px solid #36d5f2 !important;
    box-shadow: 0px 4px 20px rgba(54, 213, 242, 0.8) !important;

    color: #36d5f2 !important;
  }

  .disabled {
    background: #ffffff !important;
    border: 3px solid #e5e5e5 !important;
    box-shadow: 0px 4px 20px rgba(54, 213, 242, 0) !important;

    color: #e5e5e5 !important;
  }

  .disabled:hover {
    background: #ffffff !important;
    border: 3px solid #e5e5e5 !important;
    box-shadow: 0px 4px 20px rgba(54, 213, 242, 0) !important;

    color: #e5e5e5 !important;
  }

  .blank {
    border: 3px solid #e5e5e5 !important;
    border-radius: 100% !important;
    height: 60px !important;
    width: 60px !important;
    margin: 10px;
  }

  .blank:hover {
    background: #ffffff !important;
    border: 3px solid #e5e5e5 !important;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0) !important;
  }

  // Datepicker scss end
  // Custom section scss start
  .mini__customSet {
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  // Custom section scss end
  h1 {
    text-align: center;
  }

  // .setupMain__content {
  //   overflow-y: scroll;
  // }

  .setupMain__content__missionChild {
    display: flex;
    flex-direction: row;
    margin-top: -30px;
    margin-left: -20px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .setupMain__content__appAdd {
    margin: 0 auto;
    margin-top: auto;
    margin-left: 0;
    // margin-top: 30px;
    width: 100%;
    display: block;
    // height: 400px !important;
  }

  // .scheduler-grid {
  //   overflow: auto;
  //   height: 400px;
  //   grid-gap: 0 20px;
  // }

  // .MissionExplication {
  //   margin-top: 40px;
  // }

  .appAddElement {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
  }

  .setupMain__title {
    width: 100%;
    margin-bottom: 20px;
    font-size: 25px;
    text-align: center;
  }

  // .setupMain__content__appAdd {
  //   display: grid;
  //   grid-template-columns: 1fr 1fr 1fr;
  //   grid-gap: 20px;
  // }

  // .appAddElement:nth-child(1) {
  // 	padding-left: 0;
  // }
  .megaMission__rangeValue,
  .mini__dailySet__text,
  .miniMission__repeat,
  .skillMission__choose,
  .skillMission__additionalText {
    color: #7a615a;

    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 30px;
  }

  .show_text {
    color: #7a615a;

    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 34px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .miniMission__repeat__types,
  .skillMission__repeat__types {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  .miniMission__repeat__types .appLessonBigBtn {
    margin: 10px;
  }

  .mini__dailySet {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .mini__dailySet > .addButton {
    margin: 0 auto;
    margin-top: 40px;
  }

  .megaMission__rangeValue > span {
    color: #36d5f2;
  }

  .appInputRange {
    margin-top: 40px;
  }

  .missions__Money {
    margin-top: 50px !important;
  }

  .skillMission__additional {
    margin-top: 30px;
  }

  .skill__additional {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
    display: none;
  }

  .addition__types {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .next__Button {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center !important;
  }

  .comingSoon__yellow {
    font-style: normal;
    font-weight: normal;
    font-size: 55px;
    line-height: 67px;
    color: #ffd44f;
    text-align: center;
  }

  .comingSoon__icon__yellow {
    display: flex;
    justify-content: center;
    font-size: 55px;
    color: #ffd44f;
  }

  .comingSoon__red {
    font-style: normal;
    font-weight: normal;
    font-size: 55px;
    line-height: 67px;
    color: #ff4f79;
    text-align: center;
  }

  .comingSoon__icon__red {
    display: flex;
    justify-content: center;
    font-size: 55px;
    color: #ff4f79;
  }

  @media screen and (max-width: 1200px) {
    .setupMain__content__appAdd {
      margin-bottom: 30px;
    }
  }

  @media screen and (max-width: 900px) {
    .setupMain__content__appAdd {
      margin-top: 50px;
      grid-template-columns: 1fr 1fr;
    }
  }

  @media only screen and (max-width: 650px) {
    .setupMain__content__appAdd {
      grid-template-columns: 1fr;
      width: 100%;
    }

    .appAddElement {
      width: 100%;
    }

    .skillMission__repeat__types,
    .miniMission__repeat__types {
      justify-content: center;
    }
  }
  .childList {
    display: flex;
    overflow-x: scroll;
    padding: 10px;
    /*width: 100%;*/
  }
  .childList::-webkit-scrollbar {
    display: none;
  }
  .missionChild__box {
    padding-top: 0;
  }
  .setupMissionContainer {
    width: 100%;
  }
  @media only screen and (max-width: 820px) {
    .setupMain__title {
      font-size: 20px;
      text-align: center;
      padding: 0 20px;
      margin-top: 10px;
    }
    .setupMain__content__appAdd {
      overflow: unset;
    }
    .setupMain__content__missionChild {
      overflow-x: scroll;
      background: red;
      width: 100%;
      height: 200px;
      display: flex;
      flex-wrap: unset;
    }
    .setupMain__content__missionChild {
      display: unset !important;
    }
    .appAddElement {
      display: flex;
      flex-direction: column;
    }
    .childList {
      /*margin: 0 10px;*/
      /*background: #f9f9f9;*/
      padding: 10px 0 10px 5px;
    }
    .setupMissionContainer {
      padding: 20px;
    }
    .next__Button {
      position: fixed;
      bottom: 0;
      left: 0;
      background: white;
      width: 100%;
    }
    .setupMain__button {
      min-height: 45px;
    }
    .appButton {
      height: 20px;
      margin-top: 10px;
      // margin-bottom: 10px !important;
    }
    .missionCard {
      margin-bottom: 0;
      margin-top: 10px;
    }
  }

  .missionHour {
    list-style-type: none;
    margin: 25px 0 0 0;
    padding: 0;
  }

  .missionHour li {
    float: left;
    margin: 0 5px 0 0;
    width: 130px;
    height: 40px;
    position: relative;
  }

  .missionHour label,
  .missionHour input {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .missionHour input[type="radio"] {
    opacity: 0.01;
    z-index: 100;
  }

  .missionHour input[type="radio"]:checked + label,
  .Checked + label {
    background: #ff4f79;
    color: white;
  }

  .missionHour label {
    background: #e5e5e5;
    color: white;
    border-radius: 100px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 90;
  }

  .missionHour label:hover {
    background: #ddd;
  }

  .setupMissionSuccessCard {
    height: 360px;
  }

  @media screen and (max-width: 550px) {
    .setupMissionSuccessCard {
      height: 250px;
    }
    .setupMain__cards .setupMain__content__appAdd {
      margin-top: 0;
    }

    .setupMain__cards .childList {
      padding: 0;
    }
    .setupMain__content__appAdd .scheduler-grid {
      margin-top: 0;
    }
  }

  @media screen and (max-width: 800px) {
    .setupMain__content {
      margin: 0;
      padding: 0;
      position: relative;
    }
  }

  #missions {
    margin: 30px 0;

    .family_container {
      margin-top: 20px;
      background: white;
      padding: 25px;
      border-radius: 28px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .family_text {
      color: var(--ternary);
      text-align: right;

      &__title {
        font-weight: bold;
        font-size: 10px;
        font-weight: 700;
        letter-spacing: 0.03rem;
        color: var(--main);
      }

      &__amount {
        font-size: 22px;
        font-weight: 600;
        letter-spacing: 0.02rem;
        color: var(--main);
        line-height: 20px;
      }
    }

    .family_button {
      width: 60px;
      height: 60px;
      border: none;
      border-radius: 50%;
      background: var(--main);
      display: flex;
      justify-content: center;
      align-items: center;

      &:active {
        opacity: 0.8;
      }

      svg {
        width: 30px;
        height: 30px;
      }
    }
  }

  .nextButtons {
    // position: fixed;
    bottom: 0;
    width: 100%;
    padding: 0;
    left: 0;
    margin-top: auto;

    &__active {
      background: var(--main) !important;
    }

    &__next {
      background: #e5e5e5;
      width: 100%;
      height: 55px;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 16px;
      cursor: pointer;
      border-radius: 100px;
      margin-bottom: 0;
      text-transform: uppercase;

      &:active {
        opacity: 0.8;
      }
    }

    &__skip {
      background: white;
      width: 100%;
      height: 55px;
      color: var(--main);
      border: 1px solid var(--main);
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 16px;
      cursor: pointer;
      border-radius: 100px;
      text-transform: uppercase;

      &:active {
        opacity: 0.8;
      }
    }
  }

  .updateChildButton {
    background: var(--red);
    border: none;
    width: 100%;
    color: white;
    padding: 15px;
    border-radius: 50px;
    font-size: 16px;
    font-weight: 700;
    margin-top: 30px;
    cursor: pointer;
  }

  .addMissionExpl {
    flex: 1;
  }

  .setupWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
</style>
